import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { StaticDataService } from '../staticData/static-data.service';
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private readonly storage: Storage,
    private readonly httpClient: HttpClient,
    private readonly staticDataService: StaticDataService,
  ) {

  }

  async setHeaders() {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + await this.storage.get('token')
      })
    };
    return options;
  }

  async post(serviceName: string, data: any) {
    const options = await this.setHeaders();
    const lng = await this.storage.get('SELECTED_LANGUAGE');
    return this.httpClient.post(
      this.staticDataService.settings.server +
      lng + '/' +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      serviceName, data, options);
  }

  async get(serviceName: string) {
    const options = await this.setHeaders();
    const lng = await this.storage.get('SELECTED_LANGUAGE');
    return this.httpClient.get(
      this.staticDataService.settings.server +
      lng + '/' +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      serviceName, options);
  }

  async put(serviceName: string, data: any) {
    const options = await this.setHeaders();
    const lng = await this.storage.get('SELECTED_LANGUAGE');
    return this.httpClient.put(
      this.staticDataService.settings.server +
      lng + '/' +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      serviceName, data, options);
  }

  async delete(serviceName: string, data: any) {
    const options = await this.setHeaders();
    const lng = await this.storage.get('SELECTED_LANGUAGE');
    return this.httpClient.delete(
      this.staticDataService.settings.server +
      lng + '/' +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      serviceName, options);
  }
}
