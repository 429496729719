import { Component } from '@angular/core';
import { Config, ModalController, NavParams } from '@ionic/angular';
import { GlobalService } from '../../providers/global/global.service';
import { HttpService } from '../../providers/httpService/http.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../providers/commonService/common-service.service';




@Component({
  selector: 'page-notification-filter',
  templateUrl: 'notification-filter.html',
  styleUrls: ['./notification-filter.scss'],
})
export class NotificationFilterPage {
  ios: boolean;
  bodyNotifications: any;
  notifications: { name: string, bodyId: string, isChecked: boolean }[] = [];
  constructor(
    private config: Config,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public globalService: GlobalService,
    private httpService: HttpService,
    public translate: TranslateService,
    public commonService: CommonService,
  ) { }

  ionViewWillEnter() {
    this.ios = this.config.get('mode') === `ios`;

    // passed in array of track names that should be excluded (unchecked)
    const excludedNotificationsNames = this.navParams.get('excludedNotifications');
    console.log(excludedNotificationsNames, 'excludedNotificationsNames');
    this.notifications = excludedNotificationsNames;
    // this.confData.getNotifications().subscribe((notifications: any[]) => {
    //   notifications.forEach(notification => {
    //     this.notifications.push({
    //       name: notification.name,
    //       bodyId: notification.bodyId,
    //       isChecked: (excludedNotificationsNames.indexOf(notification.name) === -1)
    //     });
    //   });
    // });


  }

  selectAll(check: boolean) {
    // set all to checked or unchecked
    this.notifications.forEach(notification => {
      notification.isChecked = check;
    });
  }

  async applyFilters() {
    // Pass back a new array of track names to exclude
    const excludedNotificationNames = this.notifications.filter(c => !c.isChecked).map(c => c.name);
    // this.notifications.filter(c => {
    //   !c.isChecked;
    //   console.log(c, '!c.isChecked');

    // })
    this.bodyNotifications = {
      locale: 'fr',
    };
    this.notifications.forEach(element => {
      this.bodyNotifications[element.bodyId] = element.isChecked; // You can add a property:

    });
    console.log(this.bodyNotifications, 'bodyNotificationsbodyNotifications');

    await this.globalService.showLoader(this.translate.instant('common.LOADING'));

    (await this.httpService.put('updateProfile', this.bodyNotifications)).subscribe(async (data: any) => {
      console.log(data, 'data data');
      await this.globalService.dismissLoader();

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      await this.globalService.dismissLoader();
      this.commonService.handleError(error);
    });

    this.dismiss(excludedNotificationNames);
  }

  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss(data);
  }

}
