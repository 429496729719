import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { MenuController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class CheckAuth implements CanLoad {
  constructor(
    private storage: Storage,
    private router: Router,
    private menu: MenuController,
  ) { }

  async canLoad() {
    return await this.storage.get('hasLoggedIn').then(async res => {
      if (res) {
        await this.menu.enable(true);
        return true;
      } else {
        await this.menu.close();
        await this.menu.enable(false);
        this.router.navigate(['']);
        return false;
      }
    });
  }
}
