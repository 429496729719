import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { MenuController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class CheckNotAuth implements CanLoad {

  constructor(
    private storage: Storage,
    private router: Router,
    private menu: MenuController,
  ) {
    console.log('CheckNotAuth');
  }

  async canLoad() {
    console.log('CheckNotAuth', 'canLoad');
    return await this.storage.get('hasLoggedIn').then(async res => {
      if (res) {
        await this.menu.enable(true);
        this.router.navigateByUrl('/app/tabs/news', { replaceUrl: true });
        return false;
      } else {
        await this.menu.close();
        await this.menu.enable(false);
        return true;
      }
    });
  }
}
