import { Injectable } from '@angular/core';
import { StaticDataService } from '../staticData/static-data.service';
import { Platform } from '@ionic/angular';
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  public firebaseConfig = {
    apiKey: 'AIzaSyBtuubU855UvydF5lkT1c01X1TBnoGI6HI',
    authDomain: 'ttsport-1b70f.firebaseapp.com',
    databaseURL: 'https://ttsport-1b70f.firebaseio.com',
    projectId: 'ttsport-1b70f',
    storageBucket: 'ttsport-1b70f.appspot.com',
    messagingSenderId: '87114322546',
    appId: '1:87114322546:web:69cb2b5bb11b6505eea8eb'
  };
  constructor(
    private readonly http: HttpClient,
    private readonly staticDataService: StaticDataService,
    // private fcm: FCM,
    private platform: Platform,
    private readonly storage: Storage,
    public globalService: GlobalService,

  ) { }

  async setHeaders() {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + await this.storage.get('token')
      })
    };
    return options;
  }

  async get() {
  /*   return new Promise(resolve => {
      this.http.get(this.staticDataService.settings.server + 'fr/' + this.staticDataService.settings.apiURL + this.staticDataService.settings.apiVersion + 'pushNotificationRegistration').subscribe(data => {
        resolve(data);
        console.log(data);
      },
        err => {
          console.log(err);
        });
    });*/
   const options =  await this.setHeaders();
    const url = this.staticDataService.settings.server + 'fr/' + this.staticDataService.settings.apiURL + this.staticDataService.settings.apiVersion + 'pushNotificationRegistration';
    return this.http.get(url, options);
  }

  async add(postData: {}) {

    const options =  await this.setHeaders();
    const url = this.staticDataService.settings.server + 'fr/' + this.staticDataService.settings.apiURL + this.staticDataService.settings.apiVersion + 'pushNotificationRegistration';
    return this.http.post(url, postData, options);

  }

  async update(postData: {}) {
    const options =  await this.setHeaders();
    const url = this.staticDataService.settings.server + 'fr/' + this.staticDataService.settings.apiURL + this.staticDataService.settings.apiVersion + 'pushNotificationRegistration';
    return this.http.put(url, postData, options);

  }

}
