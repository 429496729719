import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { MenuController } from '@ionic/angular';
import { LanguageService } from './languages/language.service';
@Injectable({
  providedIn: 'root'
})
export class CheckLang implements CanLoad {
  constructor(
    private storage: Storage,
    private router: Router,
    private menu: MenuController,
    public languagesService: LanguageService,
  ) { }

  async canLoad() {
    const res = await this.storage.get('SELECTED_LANGUAGE');
    console.log('CheckLang', res);
    // return this.storage.get('SELECTED_LANGUAGE').then(async res => {
    if (res === null) {
      this.router.navigateByUrl('/welcome', { replaceUrl: true });
      return false;
    } else {
      // console.log( res, 'res!!');

      this.languagesService.setLanguage(res);
      return true;
    }
    // });
  }
}
