import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { StaticDataService } from '../../../../../providers/staticData/static-data.service';
import { HttpService } from '../../../../../providers/httpService/http.service';
import { GlobalService } from '../../../../../providers/global/global.service';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { IonContent } from '@ionic/angular';

import * as moment from 'moment';
import { CommonService } from '../../../../../providers/commonService/common-service.service';
import { TranslateFromApiService } from '../../../../../providers/translate/translate-from-api.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.page.html',
  styleUrls: ['./video-player.page.scss'],
})
export class VideoPlayerPage implements OnInit {

  @ViewChild(IonContent, { static: true })
  content: IonContent;

  videoSelected: any = {};

  public id: any;
  public video: any;
  public title: any;
  public date: any;
  public displayVideo: any;
  public trackVideo: any;
  track: any = [];

  relatedListVideo: any;
  linkedVideos: any = [];
  limitedVideos: any = [];
  indexStart = 0;
  indexFinish = 1;
  collection = 5;
  publishedDate: any;

  videosBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    id: null
  };

  relatedVideosBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width
  };

  constructor(
    public modalCtrl: ModalController,
    public activatedRoute: ActivatedRoute,
    public streamingMedia: StreamingMedia,
    public readonly staticDataService: StaticDataService,
    public httpService: HttpService,
    public globalService: GlobalService,
    public translate: TranslateService,
    public router: Router,
    public storage: Storage,
    public platform: Platform,
    public readonly commonService: CommonService,
    public translateFromApiService: TranslateFromApiService,
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.runVideo();
  }

  async runVideo(item?) {
    console.log('read video');
    console.log('add another log message');

    this.video = null;

    await this.globalService.showLoader(this.translate.instant('common.LOADING'));
    if (item) {
      this.videosBody = {
        locale: this.staticDataService.settings.home.lgn,
        width: this.staticDataService.settings.home.width,
        id: item
      };

    } else {
      this.videosBody.id = this.id;
    }


    (await this.httpService.post('videos', this.videosBody)).subscribe(async (dataVideo: any) => {
      await this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
        this.videoSelected = this.translateFromApiService.translate(lngLocal, dataVideo);
        console.log('this.videoSelected', this.videoSelected, this.staticDataService.settings.server + this.videoSelected.file);
        this.video = this.staticDataService.settings.server + this.videoSelected.file;
      });
      this.relatedVideos();
      await this.globalService.dismissLoader();

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }

      await this.globalService.dismissLoader();
      this.commonService.handleError(error);
    });

  }

  videoPlayer() {
    const options: StreamingVideoOptions = {
      successCallback: () => {
        console.log('Video played');
    },
      errorCallback: (e) => {
        console.log('Error streaming');
        this.startWebPlayer();
      },
      orientation: 'landscape',
      shouldAutoClose: true,
      controls: true
    };
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.streamingMedia.playVideo(this.video, options);
    } else {
      this.startWebPlayer();
    }
  }

  startWebPlayer() {
    console.log('startWebPlayer');
    const player = <HTMLVideoElement>document.getElementById('player');
    if (player !== null) {
      player.controls = true;
      player.play().then((res) => {
        console.log('startWebPlayer', 'play');
      });
      player.requestFullscreen().then((res) => {
        console.log('startWebPlayer', 'requestFullscreen');
      });
    }
  }

  stopWebPlayer() {
    console.log('stopWebPlayer');
    const player = <HTMLVideoElement>document.getElementById('player');
    if (player !== null) {
      player.pause();
      console.log('startWebPlayer', 'pause');
    }
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave');
    this.stopWebPlayer();
  }

  async relatedVideos() {
    (await this.httpService.post('videos', this.relatedVideosBody)).subscribe(async (dataVideosRelated: any) => {
      console.log(dataVideosRelated, 'data videos');

      await this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
        dataVideosRelated.forEach(element => {
          const duration = element.duration;
          element.duration = this.globalService.convertTime(duration);
          if (lngLocal === 'ar') {
            element.publishedDate = moment(element.createdAt).locale('ar-tn').calendar();
          } else {
            element.publishedDate = moment(element.createdAt).locale(lngLocal).calendar();
          }

        });
        console.log('dataVideosRelated', dataVideosRelated);

        this.relatedListVideo = this.translateFromApiService.translate(lngLocal, dataVideosRelated);
        console.log('this.relatedListVideo ', this.relatedListVideo);

        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < this.relatedListVideo.length; index++) {
          this.linkedVideos.push(this.relatedListVideo[index]);
        }
        console.log('this.linkedVideos before shuffle', this.linkedVideos);


        this.shuffle(this.linkedVideos);
        console.log('this.linkedVideos', this.linkedVideos);

        this.linkedVideos = this.linkedVideos.filter(elem => {
          // tslint:disable-next-line: no-unused-expression
          if (elem.id !== Number(this.videosBody.id)) {
            return elem;
          }
        });
        console.log('this.linkedVideos after filterrrr', this.linkedVideos);

        this.limitedVideos = this.linkedVideos.splice(0, 5);
        console.log('this.limitedVideosssss after randomize', this.limitedVideos);


      });
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }

      await this.globalService.dismissLoader();
      this.commonService.handleError(error);
    });

  }

  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.router.navigateByUrl('/app/tabs/videos');
  }

  async playVideo(video) {
    console.log('video selected', video);
    this.video = '';
    const id = video.id;
    this.router.navigate(['/app/tabs/videos/video-player', Number(id)]);
    console.log('this.videosBody', this.videosBody);
    this.runVideo(id);
    this.content.scrollToTop(400);
  }

  async videoTrack(event?) {
    const videosTrack = {
      locale: this.staticDataService.settings.home.lgn,
      offset: this.indexStart,
      limit: this.collection,
      width: this.staticDataService.settings.home.width,
      id: this.id
    };

    (await this.httpService.post('trackVideo', videosTrack)).subscribe(async (dataTrack: any) => {
      console.log(dataTrack, 'data videos');
      this.track = dataTrack;
      console.log('this.track', this.track);

      await this.globalService.dismissLoader();

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      if (!event) {
        await this.globalService.dismissLoader();
      }
      this.commonService.handleError(error);
    });
  }


}
