import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor() { }

  public settings = {
    skin: 'tt',
    debug: false,
    appTitle: 'TT Sport',
    appName: 'TT Sport',
    appId: 'com.continuousNet.ttsport',
    sponsorAr: 'إتصالات تونس',
    sponsorEn: 'Tunisie Telecom',
    sponsorFr: 'Tunisie Telecom',
    appVersion: '0.1.9',
    appMode: 'event', // club
    eventType: 'countries', // clubs
    templateDir: '',
    sportsUrl: 'app/tabs/sport/',
    newsUrl: 'app/tabs/news/',
    banner: {
      postType: 1,
      autoClose: true,
      ignoreCountdown: true,
      change: 20000
    },
    appPages: [
      {
        title: 'my_news.TITLE',
        url: '/app/tabs/my-selection',
        icon: 'star',
        open: null,
        id: null,
      },
      {
        title: 'common.NEWS',
        url: '/app/tabs/news',
        icon: 'newspaper',
        open: null,
        id: null,
      },
      {
        title: 'videos.TITLE',
        url: '/app/tabs/videos',
        icon: 'caret-forward-circle',
        open: null,
        id: null,
      },
      {
        title: 'photos.TITLE',
        url: '/app/tabs/photos',
        icon: 'images',
        open: null,
        id: null,
      },
      {
        title: 'sport.OTHERSPORTS',
        icon: 'ellipsis-horizontal',
        url: 'app/tabs/news/16',
        open: null,
        id: null,
      },
      {
        title: 'expats.TITLE',
        url: '/app/tabs/news/5',
        icon: 'airplane',
        open: null,
        id: null,
      },
    ],
    home: {
      lgn: 'fr',
      offset: 0,
      width: 650,
      postsCount: 10,
      videosCount: 5,
      audiosCount: 5,
      photosCount: 5,
      liveChannelsCount: 5
    },
    match: {
      ended: true,
      notEnded: false,
      started: true,
      width: 50
    },
    detailMatch: {
      width: 250
    },
    teams: {
      width: 100
    },
    accessMode: 'free', // subscription
    languages: [{
      name: 'selectlanguage.ARABIC',
      locale: 'ar_TN'
    }, {
      name: 'selectlanguage.FRENCH',
      locale: 'fr_FR'
    }, {
      name: 'selectlanguage.ENGLISH',
      locale: 'en_US'
    }],
    defaultLanguageId: 0,
    defaultCountryId: 236,
    server: 'https://api.ttsport.tn/', // rest api url test
    apiURL: 'api/', // rest api url
    apiVersion: 'v1/', // rest version url
    expireCredentielsTime: 1209600, // 60 * 60 * 24 * 14
    trackingID: 'UA-114114596-1',
    cacheTimeout: {
      home: 900,
      posts: 900,
      videos: 3600,
      photos: 900,
      galleries: 3600,
      matches: 3600,
      match: 3600,
      squad: 3600,
      teams: 3600,
      days: 3600,
      tables: 3600,
      matchNotEnded: 60
    },
    liveInterval: 30000,
    amount: 3,
    currency: 'TND',
    priceName: '1TND',
    packageName: 'one month',
    packageDescription: 'TT CUP 2018 - Monthly Subscription',
    fbAppId: '2020178751550316',
    redirectUri: 'http://localhost/callback',
    googleApiKey: 'AIzaSyCOwVxZ6RkBP4BoEaRR0X4mQKzUxhoyx0A'
  };

  public androidConfig = {
    senderID: '301648983816',
    ecb: 'onNotification',
    icon: 'notify',
    iconColor: '#7FC41C'
  };

  public iosConfig = {
    badge: true,
    sound: true,
    alert: true,
    vibration: true
  };

  public shopSettings = {
    payPalSandboxId: 'AXE4muyJDCDmOMCyNjn0pJtsfUQJ6M9YX0svdo3P439WXTHpgPEWAq0OgzR0MPB42FzVrHJvrPoEhtKs',
    payPalProductionId: 'ARPcsaAps0SVGBPih0sIasaK2j3LPb2m8rZYfvfgUuEr0uZd8BOmmNFOXO6CTaT62sGlwZ6cMF8hYMMg',
    payPalEnv: 'PayPalEnvironmentSandbox',   // for testing  production for production
    payPalShopName: 'TT Cup 2018',
    payPalMerchantPrivacyPolicyURL: this.settings.server + '/en/posts/useful-information/privacy-policy',
    payPalMerchantUserAgreementURL: this.settings.server + '/en/posts/useful-information/terms-and-conditions-of-use'
  };
}
