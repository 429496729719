import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LanguagesPopoverPageRoutingModule } from './languages-popover-routing.module';

import { LanguagesPopoverPage } from './languages-popover.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LanguagesPopoverPageRoutingModule
  ],
  declarations: [LanguagesPopoverPage]
})
export class LanguagesPopoverPageModule {}
