import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguagesPopoverPageModule } from './pages/languages-popover/languages-popover.module';
import { VideoPlayerPageModule } from './pages/inside-app/media/videos/video-player/video-player.module';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { faFolder, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { NotificationFilterPage } from './components/notification-filter/notification-filter';
// FCM
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { Push } from '@ionic-native/push/ngx';
import { Device } from '@ionic-native/device/ngx';
import { JpImagePreloadModule } from '@jaspero/ng-image-preload';

import { NgxPopperModule } from 'ngx-popper';

import { TooltipsModule } from 'ionic4-tooltips';
import { Network } from '@ionic-native/network/ngx';

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { pageTransition } from './page-transition';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    GalleryModule,
    LightboxModule,
    BrowserAnimationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LanguagesPopoverPageModule,
    HttpClientModule,
    LanguagesPopoverPageModule,
    FormsModule,
    VideoPlayerPageModule,
    JpImagePreloadModule.forRoot(),
    FontAwesomeModule,
    IonicModule.forRoot({
      navAnimation: pageTransition,
      swipeBackEnabled: false,
      backButtonText: '',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxPopperModule.forRoot({
      // disableDefaultStyling: true
    }),
    TooltipsModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent, NotificationFilterPage],
  providers: [
    InAppBrowser,
    SplashScreen,
    StatusBar,
    StreamingMedia,
    // FCM,
    Push,
    Device,
    Network,
    ScreenOrientation,
    FirebaseX
  ],
  bootstrap: [AppComponent],
  entryComponents: [NotificationFilterPage]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFolder, faTrophy);
    // library.addIconPacks(fas, fab, far);
  }
}
